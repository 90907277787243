@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  font-family: "Open Sans", sans-serif;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  background: #353535;
}
::-webkit-scrollbar-thumb {
  background: #78716c;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#final-cover {
  mask-image: linear-gradient(
    to right,
    transparent,
    black 20%,
    black 80%,
    transparent
  );
}
